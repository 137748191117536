import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/components/AboutBlock/AboutBlock.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/components/FeaturesBlock/FeaturesBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/workspace/app/[locale]/Home.module.scss");
;
import(/* webpackMode: "eager" */ "/workspace/components/FlightSearchForm/FlightSearchForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/components/LandingHeroBanner/LandingHeroBanner.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
